export const test = {
  requiredFields: "Please enter an article number.",
  open: "Start workflow",
  start: "Start",
  export: "Export",
  title: "Start workflow {featureName}",
  started: "The workflow has been started",
  productNumber: "Article number",
  orderId: "Sales order ids",
  orderNumber: "Sales order number",
  orderNumberShopify: "Sales order number",
  importOrders: "Import sales orders",
  orderIdTradebyte: "Sales order ids",
  exportCancelledTracking: "Export cancellation",
  import: {
    salesOrder: "Import sales orders",
    tracking: "Export order status & tracking",
    receipt: "Export receipts",
    paymentTransactions: "Import payment transactions"
  }
};
