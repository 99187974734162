export const shopware6App = {
  id: 2,
  name: "shopware6",
  label: "Shopware 6",
  showTestConnection: true,
  logo: process.env.BASE_URL + "media/shopLogos/shopware.png",
  additional_params: {
    requestHeaders: {
      "Content-Type": "application/json"
    },
    body: {
      grant_type: "client_credentials",
      client_id: "{{id}}",
      client_secret: "{{secret}}"
    },
    requestMethod: "POST",
    endpoint: "/api/oauth/token"
  },
  areaBlacklist: ["migration"],
  environmentVariables: {
    presetId: process.env?.VUE_APP_SCM_APP_SHOPWARE6_PRESET_ID,
    testWorkflows: {
      product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
      salesOrder: process.env?.VUE_APP_SCM_APP_SHOPWARE6_TEST_ORDER_WORKFLOW_ID,
      stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
      prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
      category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
    },
    testBatchWorkflows: {
      salesOrder:
        process.env?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_ORDER_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_BATCH_TRACKING_WORKFLOW_ID,
      receipt: process.env?.VUE_APP_SCM_APP_TEST_BATCH_RECEIPT_WORKFLOW_ID,
      paymentTransactions:
        process.env?.VUE_APP_SCM_APP_TEST_BATCH_PAYMENT_TRANSACTION_WORKFLOW_ID
    },
    migrationWorkflows: {
      export: {
        all: process.env
          ?.VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_EXPORT_WORKFLOW_ID,
        single:
          process.env
            ?.VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
      },
      import: {
        all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
        single: process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
      }
    },
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId:
      process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_CFL_DATASET_ID,
    syncBatchWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_SYNC_BATCH_WORKFLOW_ID,
    shopImporterWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
  }
};
