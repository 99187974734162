export const ottoApp = {
  id: 8,
  name: "otto",
  useOAuth: true,
  label: "Otto",
  logo: process.env.BASE_URL + "media/shopLogos/otto.png",
  areaBlacklist: ["migration", "reporting"],
  additional_params: {
    requestHeaders: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json"
    },
    body: {
      grant_type: "client_credentials"
    },
    requestMethod: "POST",
    endpoint: "/oauth2/token"
  },
  environmentVariables: {
    oauth: {
      canToggleSandbox: true,
      response_type: "code",
      client_id: process?.env?.VUE_APP_OTTO_APP_CLIENT_ID,
      scope: "installation partnerId"
    },
    presetId: process.env?.VUE_APP_SCM_APP_OTTO_PRESET_ID,
    testWorkflows: {
      product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
      salesOrder: process.env?.VUE_APP_SCM_APP_OTTO_TEST_ORDER_WORKFLOW_ID,
      stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
      prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
      category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
    },
    testBatchWorkflows: {
      salesOrder:
        process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_ORDER_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_BATCH_TRACKING_WORKFLOW_ID,
      receipt: process.env?.VUE_APP_SCM_APP_TEST_BATCH_RECEIPT_WORKFLOW_ID,
      paymentTransactions:
        process.env?.VUE_APP_SCM_APP_TEST_BATCH_PAYMENT_TRANSACTION_WORKFLOW_ID
    },
    migrationWorkflows: {
      export: {
        all: process.env?.VUE_APP_SCM_APP_OTTO_MIGRATION_EXPORT_WORKFLOW_ID,
        single:
          process.env?.VUE_APP_SCM_APP_OTTO_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
      },
      import: {
        all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
        single: process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
      }
    },
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_OTTO_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId: process.env.VUE_APP_SCM_APP_OTTO_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId: process.env.VUE_APP_SCM_APP_OTTO_REPORTING_CFL_DATASET_ID,
    syncBatchWorkflowId:
      process.env.VUE_APP_SCM_APP_OTTO_REPORTING_SYNC_BATCH_WORKFLOW_ID
  }
};
