import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class AuthValues extends BaseService {
  get(id) {
    return ApiService.get(url, "auth_values/" + id);
  }
  getAll(params, filter = []) {
    let filterParams = this.getParams(filter);
    let options = new URLSearchParams(filterParams).toString();
    let headers = {};
    return ApiService.get(url, "auth_values?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/auth_values", data);
  }
  update(id, data) {
    return ApiService.put(url + "/auth_values/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/auth_values/" + id);
  }
}
export default new AuthValues();
